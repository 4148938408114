import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import XSSTranslate from 'components/XSSTranslate';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import ViewContainer from 'components/ViewContainer';
import StandardContainer from 'components/StandardContainer';
import gogoroViva from 'assets/img/p3/model-gogoro-viva.png';
import gogoro125 from 'assets/img/p3/model-125.png';
import wirelessCharging from 'assets/img/p3/wireless-charging-desktop.png';
import wirelessChargingIcon from 'assets/img/p3/combined-shape.png';
import taipeiZh from 'assets/img/p3/img-location-taipei-zh.svg';
import taipeiEn from 'assets/img/p3/img-location-taipei-en.svg';
import newTaipeiZh from 'assets/img/p3/img-location-newtaipei-zh.svg';
import newTaipeiEn from 'assets/img/p3/img-location-newtaipei-en.svg';
import taoyuanZh from 'assets/img/p3/img-location-taoyuan-zh.svg';
import taoyuanEn from 'assets/img/p3/img-location-taoyuan-en.svg';
import tainanZh from 'assets/img/p3/img-location-tainan-zh.svg';
import tainanEn from 'assets/img/p3/img-location-tainan-en.svg';
import yunlinZh from 'assets/img/p3/img-location-yunlin-zh.svg';
import yunlinEn from 'assets/img/p3/img-location-yunlin-en.svg';
import kaohsiungZh from 'assets/img/p3/img-location-kaohsiung-zh.svg';
import kaohsiungEn from 'assets/img/p3/img-location-kaohsiung-en.svg';
import taichungZh from 'assets/img/p3/img-location-taichung-zh.svg';
import taichungEn from 'assets/img/p3/img-location-taichung-en.svg';
import hsinchuZh from 'assets/img/p3/img-location-hsinchu-zh.svg';
import hsinchuEn from 'assets/img/p3/img-location-hsinchu-en.svg';
import dotNewTaipeiCityZhMobile from 'assets/img/p3/img-newtaipeicity-zh-mobile.svg';
import dotNewTaipeiCityZhDesktop from 'assets/img/p3/img-newtaipeicity-zh-desktop.svg';
import dotNewTaipeiCityEnMobile from 'assets/img/p3/img-newtaipeicity-en-mobile.svg';
import dotNewTaipeiCityEnDesktop from 'assets/img/p3/img-newtaipeicity-en-desktop.svg';
import scan from 'assets/img/p3/icon-scan.png';
import tap from 'assets/img/p3/icon-tap.png';
import PricingMotion from './PricingMotion';
import {
    SERVICE_TYPE_FREEFLOATING,
    SERVICE_TYPE_PRICING,
} from 'constants/route';
import ServiceSwitch from './ServiceSwitch';
import './service.scss';

const DEVICE_MOBILE = 'mobile';
const DEVICE_DESKTOP = 'desktop';

class Service extends Component {
    constructor(props) {
        super(props);

        this.state = {
            triggerBadge: false,
            triggerPricing: false,
            serviceSwitch: false,// GoShare: false; GoShare DOTS: true;
        };

        this.elModelViva = React.createRef();
        this.elModel2 = React.createRef();
        this.elBadge = React.createRef();
        this.elPricing = React.createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        const { serviceSwitch: prevServiceSwitch } = prevState;
        const { serviceSwitch } = this.state;

        if(prevServiceSwitch !== serviceSwitch) {
            setTimeout(() => {
                this.setState({
                    triggerPricing: true,
                });
            }, 100);
        }

    }

    componentDidMount() {
        this.handlehash();

        document.addEventListener('scroll', this.handleScrolling, false);
        window.onhashchange = this.handlehash;
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScrolling, false);
        window.onhashchange = null;
    }

    handlehash = () => {
        this.scrollToHash();

    }

    scrollIntoEle = (eleName) => {
        document.querySelector(`#${eleName}`).scrollIntoView();
    }

    scrollToHash = () => {
        const hashList = window.location.hash.split('#');

        hashList.forEach( hashName => {
            switch (hashName) {
                case SERVICE_TYPE_PRICING:
                    this.setState({
                        serviceSwitch: true,
                    }, () => {
                        this.scrollIntoEle(hashName);

                        // wait scan & tap and go animation
                        setTimeout(() => {
                            this.scrollIntoEle(hashName);
                        },500);
                    });
                    break;
                case SERVICE_TYPE_FREEFLOATING:
                    this.scrollIntoEle(hashName);
                    break;
                default:
                    return;
                }
        });
    }

    handleScrolling = () => {
        // set `triggerBadge` as true
        const windowHeight = window.innerHeight;
        const modelVivaPos = this.elModelViva.current.getBoundingClientRect();
        const model2Pos = this.elModel2.current.getBoundingClientRect();
        const badgePos = this.elBadge.current.getBoundingClientRect();
        const pricingPos = this.elPricing.current.getBoundingClientRect();
        const newState = {};

        if (windowHeight > modelVivaPos.bottom) {
            newState.triggerModelViva = true;
        }

        if (windowHeight > model2Pos.bottom) {
            newState.triggerModel2 = true;
        }

        if (windowHeight > badgePos.top) {
            newState.triggerBadge = true;
        }

        if (windowHeight > pricingPos.bottom) {
            newState.triggerPricing = true;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    handleServiceSwitchClick = (serviceSwitch) => {
        this.setState({
            serviceSwitch,
            triggerPricing: false,
        });
    }

    getServiceZoneImg = () => {
        const { i18n } = this.props;
        const { locale } = i18n;
        const isZh = ['zh', 'zh-TW'].indexOf(locale) > -1;

        return {
            taipei: isZh ? taipeiZh : taipeiEn,
            newTaipei: isZh ? newTaipeiZh : newTaipeiEn,
            taoyuan: isZh ? taoyuanZh : taoyuanEn,
            tainan: isZh ? tainanZh :tainanEn,
            yunlin: isZh ? yunlinZh : yunlinEn,
            kaohsiung: isZh ? kaohsiungZh : kaohsiungEn,
            taichung: isZh ? taichungZh : taichungEn,
            hsinchu: isZh ? hsinchuZh : hsinchuEn,
            dotNewTaipeiCity: {
                [DEVICE_MOBILE]: isZh ? dotNewTaipeiCityZhMobile : dotNewTaipeiCityEnMobile,
                [DEVICE_DESKTOP]: isZh ? dotNewTaipeiCityZhDesktop : dotNewTaipeiCityEnDesktop,
            }
        };
    }

    render() {
        const { triggerModelViva, triggerModel2, triggerBadge, triggerPricing, serviceSwitch } = this.state;
        const modelVivaCls = classNames({
            'feature': true,
            'model-viva': true,
            'active': triggerModelViva,
        });
        const model2Cls = classNames({
            'feature': true,
            'model-2': true,
            'active': triggerModel2,
        });
        const badgeCls = classNames({
            'go-badges': true,
            'active': triggerBadge,
        });
        const {
            taipei,
            newTaipei,
            taoyuan,
            tainan,
            dotNewTaipeiCity,
            yunlin,
            kaohsiung,
            taichung,
            hsinchu,
        } = this.getServiceZoneImg();

        const stringXSSWhiteList = {
            span: ['class'],
        };

        return (
            <ViewContainer className="service">
                <Header show sticky />
                <Hero langkey="service.title" id={ SERVICE_TYPE_FREEFLOATING } />
                <StandardContainer className="models">
                    <div className="feature-wrap">
                        <img src={ gogoroViva } className="feature model-viva-img" alt="" />
                        <div ref={ this.elModelViva } className={ modelVivaCls }>
                            <Translate value="service.model-f-caption" tag="h2" />
                            <Translate value="service.model-f-subcaption" tag="h3" />
                            <ul>
                                <Translate value="service.model-f-feature-1" tag="li" />
                                <Translate value="service.model-f-feature-2" tag="li" />
                            </ul>
                            <div className="wireless-charging">
                                <img src={ wirelessCharging } className="icon" alt="" />
                                <div className="indicator">
                                    <img src={ wirelessChargingIcon } className="icon" alt="" />
                                    <Translate value="service.model-f-charging-1" tag="p" />
                                    <Translate value="service.model-f-charging-2" tag="span" className="note" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feature-wrap">
                        <img src={ gogoro125 } className="feature model-2-img" alt="" />
                        <div ref={ this.elModel2 } className={ model2Cls }>
                            <Translate value="service.model-b-caption" tag="h2" />
                            <XSSTranslate className="service-models" value="service.model-b-subcaption" tag="h3" whiteList={ stringXSSWhiteList } />
                            <ul>
                                <Translate value="service.model-b-feature-1" tag="li" />
                                <Translate value="service.model-b-feature-2" tag="li" />
                            </ul>
                        </div>
                    </div>
                </StandardContainer>
                <StandardContainer className="most-fun">
                    <div ref={ this.elBadge } className={ badgeCls }>
                        <div className="feature-wrap">
                            <img src={ tap } className="icon" alt="" />
                            <article className="content">
                                <Translate className="title" value="service.tap-and-go-caption" tag="h2" />
                                <XSSTranslate className="description" value="service.tap-and-go-content" tag="p" whiteList={ stringXSSWhiteList } />
                                <Translate className="notes" value="service.tap-and-go-note" tag="p" />
                            </article>
                        </div>
                        <div className="feature-wrap">
                            <img src={ scan } className="icon" alt="" />
                            <article className="content">
                                <Translate className="title" value="service.scan-and-go-caption" tag="h2" />
                                <Translate className="description" value="service.scan-and-go-content" tag="p" />
                            </article>
                        </div>
                    </div>
                    <div className="pricing-anchor" id={ SERVICE_TYPE_PRICING }></div>
                    <section className="most-fun-container">
                        <XSSTranslate value="service.fun-and-easy-caption" tag="h2" whiteList={ stringXSSWhiteList } />
                        <Translate value="service.fun-and-easy-content" tag="p" />
                        <ServiceSwitch
                            isDots={ serviceSwitch }
                            onChange={ this.handleServiceSwitchClick }
                        />
                        <PricingMotion
                            innerRef={ this.elPricing }
                            active={ triggerPricing }
                            isDots={ serviceSwitch }
                        />
                        <Translate value="service.fun-and-easy-notice" className="notice" tag="p" />
                    </section>
                </StandardContainer>
                <StandardContainer className="find-us">
                    <XSSTranslate value="service.find-us-in-ur-city-caption" tag="h2" whiteList={ stringXSSWhiteList } />
                    <div className="service-zones">
                        <section className="find-us-city">
                            <Translate value="service.find-us-in-ur-city-title" className="title" tag="h3" />
                            <XSSTranslate value="service.find-us-in-ur-city-description" className="description" tag="p" whiteList={ stringXSSWhiteList } />
                            <div className="zones">
                                <div className="zone">
                                    <img src={ hsinchu } className="location" alt={ I18n.t('service.hsinchu') }  />
                                </div>
                                <div className="zone">
                                    <img src={ taichung } className="location" alt={ I18n.t('service.taichung') } />
                                </div>
                                <div className="zone">
                                    <img src={ kaohsiung } className="location" alt={ I18n.t('service.kaohsiung') } />
                                </div>
                                <div className="zone">
                                    <img src={ yunlin } className="location" alt={ I18n.t('service.yunlin') } />
                                </div>
                                <div className="zone">
                                    <img src={ newTaipei } className="location" alt={ I18n.t('service.newTaipei') } />
                                </div>
                                <div className="zone">
                                    <img src={ tainan } className="location" alt={ I18n.t('service.tainan') } />
                                </div>
                                <div className="zone">
                                    <img src={ taipei } className="location" alt={ I18n.t('service.taipei') } />
                                </div>
                                <div className="zone">
                                    <img src={ taoyuan } className="location" alt={ I18n.t('service.taoyuan') } />
                                </div>
                            </div>
                            <Translate value="service.find-us-in-ur-city-notice-2" className="notice" tag="p" />
                        </section>
                        <section className="find-us-dots">
                            <Translate value="service.find-us-in-dots-title" className="title" tag="h3" />
                            <XSSTranslate value="service.find-us-in-dots-description" className="description" tag="p" whiteList={ stringXSSWhiteList } />
                            <div className="area">
                                <img src={ dotNewTaipeiCity[DEVICE_MOBILE] } className="dots-mobile" alt="New Taipei City"/>
                                <img src={ dotNewTaipeiCity[DEVICE_DESKTOP] } className="dots-desktop" alt="New Taipei City"/>
                            </div>
                        </section>
                    </div>
                    <Translate value="service.find-us-in-ur-city-notice-1" className="notice" tag="p" />
                    <Translate value="service.find-us-in-ur-city-notice-2" className="notice" tag="p" />
                </StandardContainer>
                <Footer />
            </ViewContainer>
        );
    }
}

export default withRouter(connect(state => ({ i18n: state.i18n }))(Service));
