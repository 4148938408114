
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StringWithLink from 'components/StringWithLink';
import {
    PRIVACY_POLICY_LINK,
} from 'components/Footer';

import './privacy.scss';

const COOKIE_NAME = 'Y0hKcGRtRmplUT09';
const EXPIRED_DAYS = 1;
const DAY = 86400000;

class Privacy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accept: false,
        };
    }

    componentDidMount() {
        const now = Date.now();
        const timestamp = localStorage.getItem(COOKIE_NAME);
        const isExpired = (now >= timestamp) ? true : false;

        if (timestamp && !isExpired) {
            this.setState({
                accept: true,
            });
        }

        if (timestamp && isExpired) {
            localStorage.removeItem(COOKIE_NAME);
        }
    }

    handleAcceptClick = () => {
        const expiredTimestamp = Date.now() + EXPIRED_DAYS * DAY;
        localStorage.setItem(COOKIE_NAME, expiredTimestamp);
        this.setState({
            accept: true,
        });
    };

    render() {
        const { i18n } = this.props;
        const { locale } = i18n;
        const isZh = ['zh', 'zh-TW'].indexOf(locale) > -1;
        const { accept } = this.state;
        const linkOption = {
            term: {
                type: 'outside',
                value: I18n.t('privacy.link'),
                style: 'privacy-link',
                href: isZh ? PRIVACY_POLICY_LINK['ZH'] : PRIVACY_POLICY_LINK['EN'],
            }
        };
        return (
            <>
            {
                !accept ? (
                    <div className="privacy">
                    <div className="privacy-container">
                        <div className="privacy-content">
                            <StringWithLink value={ I18n.t('privacy.desp') } link={ linkOption } target="_blank" />
                        </div>
                        <button
                            className="btn btn-dark"
                            onClick={ this.handleAcceptClick }
                        >
                            <Translate value="privacy.accept" />
                        </button>
                    </div>
                </div>
                ) : null
            }
            </>
        );
    }
}


export default withRouter(connect(state => ({ i18n: state.i18n }))(Privacy));
