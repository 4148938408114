import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import LocaleNavLink from 'components/LocaleNavLink';
import {
    SERVICE_FREEFLOATING,
    SERVICE_PRICING,
} from 'constants/route';
import StandardContainer from 'components/StandardContainer';
import movingScooter from 'assets/img/p3/scooter_break.png';
import { renderAnimation } from 'helpers/lottie';
import pipeAnimationConfig from 'assets/lottie/web_goshare_pipe.json';
import dotsAnimationConfig from 'assets/lottie/web_goshare_dots.json';
import './go-everywhere.scss';

class GoEverywhere extends Component {
    static propTypes = {
        innerRef: PropTypes.shape(),
        run: PropTypes.bool,
    };

    static defaultProps = {
        innerRef: React.createRef(),
        run: false,
    };

    constructor(props) {
        super(props);

        this.elScooter = React.createRef();
        this.elDots = React.createRef();
    }

    componentDidMount() {
        renderAnimation(this.elScooter.current, pipeAnimationConfig);
        renderAnimation(this.elDots.current, dotsAnimationConfig);
    }

    render() {
        const { innerRef, run } = this.props;
        const containerCls = classNames({
            'go-everywhere': true,
            'ride-scooter': run,
        });

        return (
            <StandardContainer innerRef={ innerRef } className={ containerCls } ghostHeader>
                <div className="row row-first">
                    <div ref={ this.elScooter } className="col pipe" />
                    <article className="col description">
                        <Translate className="caption" value="go-everywhere.caption-1" tag="h3" />
                        <Translate className="content" value="go-everywhere.description-1-1" tag="p" />
                        <p className="content withLink">
                            <LocaleNavLink className="link" to={ SERVICE_FREEFLOATING }>
                                <Translate  value="go-everywhere.read-more"/>
                            </LocaleNavLink>
                        </p>
                        <Translate className="content notice" value="go-everywhere.description-1-2" tag="p" />
                    </article>
                </div>
                <div className="row row-second">
                    <div className="col dots" ref={ this.elDots }></div>
                    <article className="col description">
                        <Translate className="caption" value="go-everywhere.caption-2" tag="h3" />
                        <Translate className="content" value="go-everywhere.description-2-1" tag="p" />
                        <p className="content withLink">
                            <LocaleNavLink className="link" to={ SERVICE_PRICING }>
                                <Translate  value="go-everywhere.read-more"/>
                            </LocaleNavLink>
                        </p>
                    </article>
                </div>
                <img
                    src={ movingScooter }
                    className="moving-scooter"
                    alt="Gogoro 電動共享機車,scooter sharing"
                />
            </StandardContainer>
        );
    }
}

export default GoEverywhere;
