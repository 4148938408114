const lang = {
    'title': 'Everything you need for daily mobility.',
    'model-f-caption': 'Commuter\'s choice',
    'model-f-subcaption': 'Gogoro VIVA / Gogoro JEGO',
    'model-f-feature-1': 'Easy to operate. Cool for a short-distance daily commutes.',
    'model-f-feature-2': 'Ideal for those who are qualified to ride light motorcycles.',
    'model-f-charging-1': 'Mobile mount with wireless charging.',
    'model-f-charging-2': '* Your mobile devices need to support wireless charging.',
    'model-b-caption': 'Great for exploration',
    'model-b-subcaption': '<span class="text-wrap-sm">Gogoro VIVA MIX / Gogoro 3 / </span>Ai-1 Comfort / Gogoro 2',
    'model-b-feature-1': 'Delivers super powered performance. Ideal for those riding longer distances.',
    'model-b-feature-2': 'Ideal for those who are qualified to ride general heavy motorcycles.',
    'scan-and-go-caption': 'SCAN & GO',
    'scan-and-go-content': 'With Scan & Go, you can quickly and easily scan a QR Code on any GoShare or GoShare DOTS scooter to reserve and ride.',
    'tap-and-go-caption': 'TAP & GO',
    'tap-and-go-content': '<span class="text-wrap-ex-sm">Seamlessly tap and go with GoShare at your fingertips!</span> We are the world’s first scooter sharing platform to feature <span class="text-wrap-ex-sm">Apple Pay with integrated NFC. Quickly reserve and ride</span><span class="text-wrap-ex-sm"> using your iPhone!</span>',
    'tap-and-go-note': '* Supported by iPhone XR and newer models',
    'fun-and-easy-caption': 'First ride is free. Surprises are waiting for you!',
    'fun-and-easy-content': 'Enjoy your first ride for free up to 30 mins.',
    'fun-and-easy-switch-goshare': 'GoShare',
    'fun-and-easy-switch-dots': 'GoShare DOTS',
    'pricing-motion-description-1': '6 mins fixed rate',
    'pricing-motion-description-2': 'Charge per minute',
    'pricing-motion-description-dots-1': '15 mins fixed rate',
    'pricing-motion-description-dots-2': 'Charge per minute',
    'fun-and-easy-notice': '* Please refer to your GoShare App for the exact cost and more promotions.',
    'find-us-in-ur-city-caption': 'Find us in your city!',
    'find-us-in-ur-city-notice-1': '* As Taiwan\'s longest green tourist route, "Route 2 Taiwan" features the most beautiful road trip experience using low-carbon transportation.',
    'find-us-in-ur-city-notice-2': '* For details on the operation area, please check the GoShare App.',
    'find-us-in-ur-city-title': 'GoShare',
    'find-us-in-ur-city-description': 'Hop on and go everywhere with a GoShare scooter. GoShare scooters can be found and returned in public parking spaces within our service areas.',
    'find-us-in-dots-title': 'GoShare DOTS',
    'find-us-in-dots-description': 'Explore popular tourist destinations with a GoShare DOTS scooter. GoShare DOTS scooters can be found and returned at designated stops within our service areas.',
    'taipei': 'Taipei',
    'taoyuan': 'Taoyuan',
    'tainan': 'Tainan',
    'newTaipei': 'New Taipei',
    'yunlin': 'Yunlin',
    'kaohsiung': 'Kaohsiung',
    'taichung': 'Taichung',
    'hsinchu': 'Hsinchu',
};

export default lang;
