const lang = {
    'caption-1': 'Go everywhere. Go forever',
    'description-1-1': 'Hop on and enjoy the ride. Unlike other sharing services, GoShare gives you access to the Gogoro Network® so you can swap your batteries at any GoStation easily and extend your ride.',
    'description-1-2': '* Please refer to GoShare App or Gogoro Network website for the details on GoStation locations.',
    'read-more': 'Read More',
    'caption-2': 'Explore Popular Tourist Attractions',
    'description-2-1': 'Specifically designed for popular tourist destinations, GoShare DOTS lets you reserve and return a GoShare DOTS scooter at designated stops 24/7. Find a bright yellow dot in the GoShare App to enjoy a ride.',

};

export default lang;
