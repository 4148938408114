import React from 'react';
import App from 'view/App';
import Home from 'view/Home';
import AboutUs from 'view/AboutUs';
import Download from 'view/Download';
import News from 'view/News';
import LostItems from 'view/LostItems';
import Service from 'view/Service';
import MAProgram from 'view/MAProgram';
import Partnerships from 'view/Partnerships';
import NotFound from 'view/NotFound';
import { Provider } from 'react-redux';
import store from 'store';
import {
    ROOT,
    ABOUT_US,
    DOWNLOAD,
    NEWS_ROOM,
    LOST_N_FOUND,
    SERVICE,
    MA_PROGRAM,
    PARTNERSHIPS,
    SPECIAL_DYNAMIC_LINK_LIST,
} from 'constants/route';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


export default (
    <Router>
        <Provider store={ store }>
            <App>
                <Switch>
                    <Route path={ ROOT } exact strict component={ Home } />
                    {
                        SPECIAL_DYNAMIC_LINK_LIST.map( (path) => (
                            <Route path={ path } exact strict component={ Download } key={ path } />
                        ))
                    }
                    <Route path={ ABOUT_US } exact strict component={ AboutUs } />
                    <Route path={ DOWNLOAD } exact strict component={ Download } />
                    <Route path={ NEWS_ROOM } exact strict component={ News } />
                    <Route path={ LOST_N_FOUND } exact strict component={ LostItems } />
                    <Route path={ SERVICE } exact strict component={ Service } />
                    <Route path={ PARTNERSHIPS } exact strict component={ Partnerships } />
                    <Route path={ `${ ROOT }(tw|us)${ ROOT }` } exact strict component={ Home } />
                    <Route path={ `${ ROOT }(tw|us)${ ABOUT_US }` } exact strict component={ AboutUs } />
                    <Route path={ `${ ROOT }(tw|us)${ DOWNLOAD }` } exact strict component={ Download } />
                    <Route path={ `${ ROOT }(tw|us)${ NEWS_ROOM }` } exact strict component={ News } />
                    <Route path={ `${ ROOT }(tw|us)${ LOST_N_FOUND }` } exact strict component={ LostItems } />
                    <Route path={ `${ ROOT }(tw|us)${ SERVICE }` } exact strict component={ Service } />
                    <Route path={ `${ ROOT }(tw|us)${ PARTNERSHIPS }` } exact strict component={ Partnerships } />
                    <Route path={ MA_PROGRAM } exact strict component={ MAProgram } />
                    <Route component={ NotFound } />
                </Switch>
            </App>
        </Provider>
    </Router>
);
